<template>
<div>
    <b-row>
        <b-col md="12">

            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión inicial informe</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12">
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(guardarDatos)">
                                    <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark" active-tab-class="">
                                        <b-tab title="Datos" active>
                                            <b-row>
                                                <b-col lg="4">
                                                    <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Proyecto:" class="mb-2">
                                                            <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInforme.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInforme.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Proyectos'}"> Registra aquí</router-link></span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="2">
                                                    <validation-provider name="ejercicio" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Ejercicio / Año:" class="mb-2">
                                                            <v-select taggable :reduce="comboEjercicio =>comboEjercicio.descripcion" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInforme.ejercicio , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInforme.ejercicio" :options="comboEjercicio" @search:blur="blurEjercicio">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="responsable" v-slot="validationContext">
                                                        <b-form-group label="Responsable:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" v-model="datosInforme.responsable"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha de contrato" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha de contrato:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de contrato" v-model="datosInforme.fechaContrato"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha de citación y aspectos ambientales" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha de citación y aspectos ambientales:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosInforme.fechaCitacionAspectos"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-if="datosInforme.urlArchivoA !== null && datosInforme.urlArchivoA != ''">
                                                    <validation-provider name="documento" v-slot="validationContext">
                                                        <b-form-group label="Documento:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInforme.urlArchivoA)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file1" v-model="datosInforme.archivoMetaA" :state="(getValidationState(validationContext) )" :placeholder="datosInforme.nombreArchivoA" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload($event,1)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-else>
                                                    <b-form-group label="Documento:" class="mb-2">
                                                        <b-form-file ref="file1" v-model="datosInforme.archivoMetaA" v-on:change="handleFileUpload($event,1)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha plan de prevencion" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha plan de prevencion:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosInforme.fechaPlanPrevencion"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-if="datosInforme.urlArchivoB !== null && datosInforme.urlArchivoB != ''">
                                                    <validation-provider name="documento" v-slot="validationContext">
                                                        <b-form-group label="Documento:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInforme.urlArchivoB)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file2" v-model="datosInforme.archivoMetaB" :state="(getValidationState(validationContext) )" :placeholder="datosInforme.nombreArchivoB" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload($event,2)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-else>
                                                    <b-form-group label="Documento:" class="mb-2">
                                                        <b-form-file ref="file2" v-model="datosInforme.archivoMetaB" v-on:change="handleFileUpload($event,2)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha de plan ambiental" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha de plan ambiental:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosInforme.fechaPlanAmbiental"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-if="datosInforme.urlArchivoC !== null && datosInforme.urlArchivoC != ''">
                                                    <validation-provider name="documento" v-slot="validationContext">
                                                        <b-form-group label="Documento:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInforme.urlArchivoC)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file3" v-model="datosInforme.archivoMetaC" :state="(getValidationState(validationContext) )" :placeholder="datosInforme.nombreArchivoC" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload($event,3)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-else>
                                                    <b-form-group label="Documento:" class="mb-2">
                                                        <b-form-file ref="file3" v-model="datosInforme.archivoMetaC" v-on:change="handleFileUpload($event,3)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha últimos RRMM" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha últimos RRMM:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosInforme.fechaUltimoRRMM"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-if="datosInforme.urlArchivoD !== null && datosInforme.urlArchivoD != ''">
                                                    <validation-provider name="documento" v-slot="validationContext">
                                                        <b-form-group label="Documento:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInforme.urlArchivoD)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file4" v-model="datosInforme.archivoMetaD" :state="(getValidationState(validationContext) )" :placeholder="datosInforme.nombreArchivoD" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload($event,4)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-else>
                                                    <b-form-group label="Documento:" class="mb-2">
                                                        <b-form-file ref="file4" v-model="datosInforme.archivoMetaD" v-on:change="handleFileUpload($event,4)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col lg="3">
                                                    <validation-provider name="fecha programa de simulacro" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha programa de simulacro:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosInforme.fechaProgramaSimulacro"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-if="datosInforme.urlArchivoE !== null && datosInforme.urlArchivoE != ''">
                                                    <validation-provider name="documento" v-slot="validationContext">
                                                        <b-form-group label="Informe siniestralidad mutua:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInforme.urlArchivoE)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file5" v-model="datosInforme.archivoMetaE" :state="(getValidationState(validationContext) )" :placeholder="datosInforme.nombreArchivoE" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload($event,5)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-else>
                                                    <b-form-group label="Informe siniestralidad mutua:" class="mb-2">
                                                        <b-form-file ref="file5" v-model="datosInforme.archivoMetaE" v-on:change="handleFileUpload($event,5)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col lg="3">
                                                    <validation-provider name="fecha próximo simulacro" :rules="{ required: true }" v-slot="validationContext">
                                                        <b-form-group label="Fecha próximo simulacro:" class="mb-2">
                                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosInforme.fechaProximoSimulacro"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-if="datosInforme.urlArchivoF !== null && datosInforme.urlArchivoF != ''">
                                                    <validation-provider name="documento" v-slot="validationContext">
                                                        <b-form-group label="Documento:" class="mb-2">
                                                            <b-input-group>
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosInforme.urlArchivoF)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file6" v-model="datosInforme.archivoMetaF" :state="(getValidationState(validationContext) )" :placeholder="datosInforme.nombreArchivoF" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload($event,6)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="3" v-else>
                                                    <b-form-group label="Documento:" class="mb-2">
                                                        <b-form-file ref="file6" v-model="datosInforme.archivoMetaF" v-on:change="handleFileUpload($event,6)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                    </b-form-group>
                                                </b-col>
                                                <!-- <b-col lg="4">
                                                    <validation-provider name="indice de incidencia" v-slot="validationContext">
                                                        <b-form-group label="Índice de incidencia:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el indice de incidencia" v-model="datosInforme.indiceIncidencia"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col lg="4">
                                                    <validation-provider name="índice de frecuencia" v-slot="validationContext">
                                                        <b-form-group label="Índice de frecuencia:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el índice de frecuencia" v-model="datosInforme.indiceFrecuencia"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col lg="4">
                                                    <validation-provider name="accidentes en el periodo:" v-slot="validationContext">
                                                        <b-form-group label="Accidentes en el periodo:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el accidentes en el periodo:" v-model="datosInforme.accidentesPeriodo"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Documentos">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Documentos</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(1, listaDocumentoSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaDocumentoSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{data.index+1}}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo1'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre1'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion1'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable1'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex1'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,1,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex1'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,1,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param, listaDocumentoSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Responsables">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Responsables</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(2, listaResponsablesSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaResponsablesSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo2'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre2'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion2'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable2'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex2'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,2,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex2'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,2,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param, listaResponsablesSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Programas, planes e IPERC">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Programas, planes e IPERC</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(3, listaProgramaSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaProgramaSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo3'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre3'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion3'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable3'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex3'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,3,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex3'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,3,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param,listaProgramaSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Procedimientos">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Procedimientos</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(4, listaProcedimientosSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaProcedimientosSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo4'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre4'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion4'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable4'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex4'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,4,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex4'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,4,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param, listaProcedimientosSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Registros">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Registros</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(5, listaRegistrosSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaRegistrosSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoRegistrosSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo5'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre5'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion5'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable5'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(tipoRegistro)="row">
                                                            <validation-provider :name="'tipo de registro'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-select :state="getValidationState(validationContext)" :options="comboTipoRegistro" value-field="idTipoRegistro" text-field="descripcion" v-model="row.item.tipoRegistro">
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex5'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,5,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex5'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,5,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param, listaRegistrosSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Incidencias">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Incidencias</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(6, listaIncidenciasSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaIncidenciasSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo6'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre6'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion6'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable6'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex6'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,6,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex6'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,6,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param,listaIncidenciasSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Monitoreo e instrumentos">
                                            <b-row>
                                                <b-col md="12 my-2">
                                                    <span class="text-muted h5">Monitoreo e instrumentos</span>
                                                    <b-button size="sm" class="float-right" variant="dark" @click="agregarDocumentoSGMA(7, listaMonitoreoInstrumentosSGMA )"><i class="fas fa-plus-circle mr-1"></i>Añadir</b-button>
                                                    <hr>
                                                    <b-table :items="listaMonitoreoInstrumentosSGMA.filter(x =>x.estado == 2)" :fields="campoDocumentoSGMA" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template v-slot:cell(codigo)="row">
                                                            <validation-provider :name="'codigo7'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese código" v-model="row.item.codigo" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre7'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese nombre" v-model="row.item.nombre" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(fechaPublicacion)="row">
                                                            <validation-provider :name="'fechaPublicacion7'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="date" v-model="row.item.fechaPublicacion" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(responsable)="row">
                                                            <validation-provider :name="'responsable7'+row.index" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-input type="text" placeholder="Ingrese responsable" v-model="row.item.responsable" :state="getValidationState(validationContext)"></b-form-input>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(documento)="row">
                                                            <b-input-group v-if="row.item.urlArchivo !== null && row.item.urlArchivo != ''">
                                                                <b-input-group-prepend>
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlArchivo)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file class="text-left" :ref="'filex7'+row.index" v-model="row.item.documento" :placeholder="row.item.nombreArchivo" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handlexFileUpload($event,7,row.index)"></b-form-file>
                                                            </b-input-group>
                                                            <b-form-file v-else class="text-left" :ref="'filex7'+row.index" v-model="row.item.documento" v-on:change="handlexFileUpload($event,7,row.index)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir" :accept="accept.toString()"></b-form-file>
                                                        </template>
                                                        <template #cell(opciones)="param">
                                                            <b-button @click="eliminarDocumentoSST(param, listaMonitoreoInstrumentosSGMA)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </b-button>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-row>
                                            <b-col lg="12" class="text-center my-2">
                                                <b-button :disabled="disabled" variant="dark" class=" mr-2" :to="{name: 'Gestión inicial 14001'}">
                                                    <i class="fas fa-arrow-left"></i> Volver
                                                </b-button>
                                                <b-button v-if="$route.params.id && checkPermissions('004-14001-GEI','u')==1" :disabled="disabled" variant="success" type="submit">
                                                    <i class="fas fa-save"></i> Guardar
                                                </b-button>
                                                <b-button v-if="!$route.params.id && checkPermissions('004-14001-GEI','c')==1" :disabled="disabled" variant="success" type="submit">
                                                    <i class="fas fa-save"></i> Guardar
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-tabs>
                                </b-form>
                            </validation-observer>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            disabled: false,
            datosInforme: {
                idGestionInicial: null,
                idProyecto: null,
                idCliente: '',
                responsable: '',
                fechaContrato: moment().format('YYYY-MM-DD'),
                fechaCitacionAspectos: moment().format('YYYY-MM-DD'),
                nombreArchivoA: '',
                urlArchivoA: '',
                archivoMetaA: null,
                fechaPlanPrevencion: moment().format('YYYY-MM-DD'),
                nombreArchivoB: '',
                urlArchivoB: '',
                archivoMetaB: null,
                fechaPlanAmbiental: moment().format('YYYY-MM-DD'),
                nombreArchivoC: '',
                urlArchivoC: '',
                archivoMetaC: null,
                fechaUltimoRRMM: moment().format('YYYY-MM-DD'),
                nombreArchivoD: '',
                urlArchivoD: '',
                archivoMetaD: null,
                fechaProgramaSimulacro: moment().format('YYYY-MM-DD'),
                nombreArchivoE: '',
                urlArchivoE: '',
                archivoMetaE: null,
                fechaProximoSimulacro: moment().format('YYYY-MM-DD'),
                nombreArchivoF: '',
                urlArchivoF: '',
                archivoMetaF: null,
                accidentesPeriodo: '',
                ejercicio: null,
            },

            listaDocumentos: [],
            listaDocumentoSGMA: [],
            listaResponsablesSGMA: [],
            listaProgramaSGMA: [],
            listaProcedimientosSGMA: [],
            listaRegistrosSGMA: [],
            listaIncidenciasSGMA: [],
            listaMonitoreoInstrumentosSGMA: [],

            comboProyecto: [],
            comboEjercicio: [],

            campoDocumentoSGMA: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: "codigo",
                label: "Codigo documento",
                class: "text-center",
            }, {
                key: "nombre",
                label: "Nombre",
                class: "text-center",
            }, {
                key: 'fechaPublicacion',
                label: 'Fecha publicación',
                class: 'text-center'
            }, {
                key: 'responsable',
                label: 'Responsable',
                class: 'text-center'
            }, {
                key: 'documento',
                label: 'Documento',
                class: 'text-center'
            }, {
                key: 'opciones',
                label: '',
                class: 'text-center'
            }],
            campoDocumentoRegistrosSGMA: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: "codigo",
                label: "Codigo documento",
                class: "text-center",
            }, {
                key: "nombre",
                label: "Nombre",
                class: "text-center",
            }, {
                key: 'fechaPublicacion',
                label: 'Fecha publicación',
                class: 'text-center'
            }, {
                key: 'responsable',
                label: 'Responsable',
                class: 'text-center'
            }, {
                key: 'tipoRegistro',
                label: 'Tipo de registro',
                class: 'text-center'
            }, {
                key: 'documento',
                label: 'Documento',
                class: 'text-center'
            }, {
                key: 'opciones',
                label: '',
                class: 'text-center'
            }],
            comboTipoRegistro: [{
                idTipoRegistro: '1',
                descripcion: 'Registros de dirección'
            }, {
                idTipoRegistro: '2',
                descripcion: 'Registros de trabajadores'
            }, {
                idTipoRegistro: '3',
                descripcion: 'Registros de comunidad'
            }],
            accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        descargarDocumento(url) {
            window.open(url)
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },

        handleFileUpload(e, i) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file${i}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`file${i}`].reset();
                    return;
                }

                // this.datosEquipo.inventarioAlmacen = this.$refs.file3.files[0];
            }
        },

        handlexFileUpload(e, i, o) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`filex${i}${o}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`filex${i}${o}`].reset();
                    return;
                }
            }
        },

        llenarComboEjercicio() {
            let anio = parseInt(moment().format('YYYY'));
            for (let i = anio - 10; i < anio + 10; i++)
                this.comboEjercicio.push({
                    descripcion: i
                })
        },
        blurEjercicio() {
            this.computedForm.refs.ejercicio.validate();
        },
        listarProyectos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proyectos", {
                        params: {
                            idCliente: me.datosInforme.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProyecto = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        guardarDatos() {
            if (this.$route.params.id) {
                this.actualizarInforme();
            } else {
                this.registrarInforme();
            }
        },

        registrarInforme() {
            let me = this;
            me.disabled = true;
            me.listaDocumentos = me.listaDocumentoSGMA.concat(me.listaResponsablesSGMA, me.listaProgramaSGMA, me.listaProcedimientosSGMA, me.listaRegistrosSGMA, me.listaIncidenciasSGMA, me.listaMonitoreoInstrumentosSGMA);
            const formData = new FormData();
            let data = 0;

            for (const i in me.listaDocumentos) {
                if (me.listaDocumentos[i].idGestionInicialDocumento == 0 && me.listaDocumentos[i].documento != null) {
                    data = data - 1;
                    formData.append("filex" + data, me.listaDocumentos[i].documento);
                } else {
                    formData.append("filex" + me.listaDocumentos[i].idGestionInicialDocumento, me.listaDocumentos[i].documento);
                };
            }
            formData.append("listaDocumentosSGMA", JSON.stringify(me.listaDocumentos));

            /*---Necesario para ruta de archivo --*/
            formData.append("file1", me.datosInforme.archivoMetaA);
            formData.append("file2", me.datosInforme.archivoMetaB);
            formData.append("file3", me.datosInforme.archivoMetaC);
            formData.append("file4", me.datosInforme.archivoMetaD);
            formData.append("file5", me.datosInforme.archivoMetaE);
            formData.append("file6", me.datosInforme.archivoMetaF);

            formData.append("folder", 'sgma/gestion-inicial');

            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosInforme.idCliente);

            formData.append("idProyecto", me.datosInforme.idProyecto);
            formData.append("responsable", me.datosInforme.responsable);
            formData.append("fechaContrato", me.datosInforme.fechaContrato);
            formData.append("fechaCitacionAspectos", me.datosInforme.fechaCitacionAspectos);
            formData.append("fechaPlanPrevencion", me.datosInforme.fechaPlanPrevencion);
            formData.append("fechaPlanAmbiental", me.datosInforme.fechaPlanAmbiental);
            formData.append("fechaUltimoRRMM", me.datosInforme.fechaUltimoRRMM);
            formData.append("fechaProgramaSimulacro", me.datosInforme.fechaProgramaSimulacro);
            formData.append("fechaProximoSimulacro", me.datosInforme.fechaProximoSimulacro);
            formData.append("accidentesPeriodo", me.datosInforme.accidentesPeriodo);
            formData.append("ejercicio", me.datosInforme.ejercicio);

            formData.append("nombreArchivoA", me.datosInforme.nombreArchivoA);
            formData.append("nombreArchivoB", me.datosInforme.nombreArchivoB);
            formData.append("nombreArchivoC", me.datosInforme.nombreArchivoC);
            formData.append("nombreArchivoD", me.datosInforme.nombreArchivoD);
            formData.append("nombreArchivoE", me.datosInforme.nombreArchivoE);
            formData.append("nombreArchivoF", me.datosInforme.nombreArchivoF);

            formData.append("urlArchivoA", me.datosInforme.urlArchivoA);
            formData.append("urlArchivoB", me.datosInforme.urlArchivoB);
            formData.append("urlArchivoC", me.datosInforme.urlArchivoC);
            formData.append("urlArchivoD", me.datosInforme.urlArchivoD);
            formData.append("urlArchivoE", me.datosInforme.urlArchivoE);
            formData.append("urlArchivoF", me.datosInforme.urlArchivoF);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-gestion-inicial-sgma",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'Gestión inicial 14001'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        actualizarInforme() {
            let me = this;
            me.disabled = true;
            me.listaDocumentos = me.listaDocumentoSGMA.concat(me.listaResponsablesSGMA, me.listaProgramaSGMA, me.listaProcedimientosSGMA, me.listaRegistrosSGMA, me.listaIncidenciasSGMA, me.listaMonitoreoInstrumentosSGMA);
            const formData = new FormData();
            let data = 0;

            for (const i in me.listaDocumentos) {
                //Aqui se envia soloa archivos .--que sean nuevos y que tengan documentos 
                if (me.listaDocumentos[i].idGestionInicialDocumento == 0 && me.listaDocumentos[i].documento != null) {
                    data = data - 1;
                    formData.append("filex" + data, me.listaDocumentos[i].documento);
                } else {
                    formData.append("filex" + me.listaDocumentos[i].idGestionInicialDocumento, me.listaDocumentos[i].documento);
                };
            }

            formData.append("listaDocumentosSGMA", JSON.stringify(me.listaDocumentos));

            /*---Necesario para ruta de archivo --*/
            formData.append("file1", me.datosInforme.archivoMetaA);
            formData.append("file2", me.datosInforme.archivoMetaB);
            formData.append("file3", me.datosInforme.archivoMetaC);
            formData.append("file4", me.datosInforme.archivoMetaD);
            formData.append("file5", me.datosInforme.archivoMetaE);
            formData.append("file6", me.datosInforme.archivoMetaF);

            formData.append("folder", 'sgma/gestion-inicial');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosInforme.idCliente);
            formData.append("idGestionInicial", me.datosInforme.idGestionInicial);

            formData.append("idProyecto", me.datosInforme.idProyecto);
            formData.append("responsable", me.datosInforme.responsable);
            formData.append("fechaContrato", me.datosInforme.fechaContrato);
            formData.append("fechaCitacionAspectos", me.datosInforme.fechaCitacionAspectos);
            formData.append("fechaPlanPrevencion", me.datosInforme.fechaPlanPrevencion);
            formData.append("fechaPlanAmbiental", me.datosInforme.fechaPlanAmbiental);
            formData.append("fechaUltimoRRMM", me.datosInforme.fechaUltimoRRMM);
            formData.append("fechaProgramaSimulacro", me.datosInforme.fechaProgramaSimulacro);
            formData.append("fechaProximoSimulacro", me.datosInforme.fechaProximoSimulacro);
            formData.append("accidentesPeriodo", me.datosInforme.accidentesPeriodo);
            formData.append("ejercicio", me.datosInforme.ejercicio);

            formData.append("nombreArchivoA", me.datosInforme.nombreArchivoA);
            formData.append("nombreArchivoB", me.datosInforme.nombreArchivoB);
            formData.append("nombreArchivoC", me.datosInforme.nombreArchivoC);
            formData.append("nombreArchivoD", me.datosInforme.nombreArchivoD);
            formData.append("nombreArchivoE", me.datosInforme.nombreArchivoE);
            formData.append("nombreArchivoF", me.datosInforme.nombreArchivoF);

            formData.append("urlArchivoA", me.datosInforme.urlArchivoA);
            formData.append("urlArchivoB", me.datosInforme.urlArchivoB);
            formData.append("urlArchivoC", me.datosInforme.urlArchivoC);
            formData.append("urlArchivoD", me.datosInforme.urlArchivoD);
            formData.append("urlArchivoE", me.datosInforme.urlArchivoE);
            formData.append("urlArchivoF", me.datosInforme.urlArchivoF);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-gestion-inicial-sgma",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'Gestión inicial 14001'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        agregarDocumentoSGMA(tipo, arr) {
            arr.push({
                idGestionInicialDocumento: 0,
                codigo: "",
                nombre: "",
                fechaPublicacion: moment().format('YYYY-MM-DD'),
                responsable: "",
                tipoRegistro: null,
                tipo: tipo,
                documento: null,
                urlArchivo: '',
                nombreArchivo: '',
                estado: 2,
            })
        },
        eliminarDocumentoSST(param, arr) {
            //if es actualizar
            if (this.$route.params.id) {
                //Array temporal , escencial para obtener la posicion cuando es esatdo 2
                let arrActivos = arr.filter(x => x.estado == 2);
                //if es ya existente
                if (arrActivos[param.index].idGestionInicialDocumento !== 0) {
                    //se vuelve estado 1
                    arrActivos[param.index].estado = 1
                    //if es neuvo
                } else if (arrActivos[param.index].idGestionInicialDocumento === 0) {
                    //se vuelve estado 0
                    arrActivos[param.index].estado = 0
                    //loop array principal si su estado es 0 eliminalo 
                    //sirve para no saturar el array
                    for (let e in arr) {
                        if (arr[e].estado == 0) {
                            arr.splice(e, 1);
                        }
                    }
                }
            } else {
                arr.splice(param.index, 1);
            }
        },

        obtenerGestionInicial() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-gestion-inicial", {
                        params: {
                            idCliente: me.datosInforme.idCliente,
                            idGestionInicial: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {

                    me.datosInforme.idGestionInicial = response.data[0].idGestionInicial;
                    me.datosInforme.idProyecto = response.data[0].idProyecto;
                    me.datosInforme.idCliente = response.data[0].idCliente;
                    me.datosInforme.responsable = response.data[0].responsable;
                    me.datosInforme.fechaContrato = response.data[0].fechaContrato;
                    me.datosInforme.fechaCitacionAspectos = response.data[0].fechaCitacionAspectos;
                    me.datosInforme.nombreArchivoA = response.data[0].nombreArchivoA;
                    me.datosInforme.urlArchivoA = response.data[0].urlArchivoA;
                    me.datosInforme.fechaPlanPrevencion = response.data[0].fechaPlanPrevencion;
                    me.datosInforme.nombreArchivoB = response.data[0].nombreArchivoB;
                    me.datosInforme.urlArchivoB = response.data[0].urlArchivoB;
                    me.datosInforme.fechaPlanAmbiental = response.data[0].fechaPlanAmbiental;
                    me.datosInforme.nombreArchivoC = response.data[0].nombreArchivoC;
                    me.datosInforme.urlArchivoC = response.data[0].urlArchivoC;
                    me.datosInforme.fechaUltimoRRMM = response.data[0].fechaUltimoRRMM;
                    me.datosInforme.nombreArchivoD = response.data[0].nombreArchivoD;
                    me.datosInforme.urlArchivoD = response.data[0].urlArchivoD;
                    me.datosInforme.fechaProgramaSimulacro = response.data[0].fechaProgramaSimulacro;
                    me.datosInforme.nombreArchivoE = response.data[0].nombreArchivoE;
                    me.datosInforme.urlArchivoE = response.data[0].urlArchivoE;
                    me.datosInforme.fechaProximoSimulacro = response.data[0].fechaProximoSimulacro;
                    me.datosInforme.nombreArchivoF = response.data[0].nombreArchivoF;
                    me.datosInforme.urlArchivoF = response.data[0].urlArchivoF;
                    me.datosInforme.accidentesPeriodo = response.data[0].accidentesPeriodo;
                    me.datosInforme.ejercicio = response.data[0].ejercicio;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Gestión inicial 14001'
                    });
                });
        },
        obtenerGestionInicialDocumentos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-gestion-inicial-documentos", {
                        params: {
                            idGestionInicial: me.$route.params.id,
                            idCliente: me.datosInforme.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentoSGMA = response.data.filter(x => x.tipo == 1);
                    me.listaResponsablesSGMA = response.data.filter(x => x.tipo == 2);
                    me.listaProgramaSGMA = response.data.filter(x => x.tipo == 3);
                    me.listaProcedimientosSGMA = response.data.filter(x => x.tipo == 4);
                    me.listaRegistrosSGMA = response.data.filter(x => x.tipo == 5);
                    me.listaIncidenciasSGMA = response.data.filter(x => x.tipo == 6);
                    me.listaMonitoreoInstrumentosSGMA = response.data.filter(x => x.tipo == 7);
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Gestión inicial 14001'
                    });
                });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosInforme.idCliente = user.uidClient;
            this.listarProyectos();
            this.llenarComboEjercicio();
            if (this.$route.params.id) {
                this.obtenerGestionInicial();
                this.obtenerGestionInicialDocumentos();
            }
        }
    }

}
</script>
